/**
 * Type: ページ
 * What: うんこミュージアム
 */
import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { scroller, animateScroll as scroll } from 'react-scroll';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Button } from '../components/Btn';
import Image from '../util/Image';
import BreadcrumbComponent from '../components/Breadcrumb';
import SimpleSwiper from '../components/Slider.fade';
import {UNKOMUSEUM_CATEGORIES, UNKOMUSEUM_GOODS} from '../config/Unkomuseum';
import ContentsListUnko from '../components/ContentsListUnko';
import PostListComponent from '../components/PostList/PostList.unkomuseum';
import TimerFunc from '../util/Timer';
import mainImg2 from '../images/unkomuseum/main-unkomuseum2.jpg';

const pageTitle = 'ただいま!!うんこミュージアムFUKUOKA presented by CHIKYUJINFUKUOKA';
const pageDescription = '累計100万人以上が来場！あの「うんこミュージアム」が福岡に帰ってくる！「ただいま!!うんこミュージアムFUKUOKA presented by CHIKYUJINFUKUOKA」3月4日（土）から開催！パワーアップして帰ってきたウンターテインメント施設に、うんこ動物が新登場！大人気の福岡の皆さまにかわいくてポップなうんこにまみれる非日常の体験をお届けします！';
const pageSlug = 'unkomuseum';
const pageLogo = 'logo-unkomuseum.jpg';

export const query = graphql`
  {
    site {
      siteMetadata {
        uuidEvent
        ticket
        ticketReady
      }
    }
  }
`;

const sliderDataobj = [
  {
    filename: mainImg2,
    noAnimation: true,
  },
];

// Data Props Template
const Template = ({ data }) => {

  const ankerLinkScroll = (e, elem, offset) => {
    e.preventDefault();
    let $offset = offset || -160;
    if (window.matchMedia('screen and (max-width: 768px)').matches) {
      $offset = offset || -110;
    }
    scroller.scrollTo(elem, {
      duration: 1200,
      delay: 0,
      offset: $offset,
      smooth: 'easeOutQuint',
    });
  };

  const url = data.site.siteMetadata;

  const [isModal, setIsModal] = useState(-1);

  // モーダル展開
  const onClickModal = (e) => {
    const num = Number(e.currentTarget.dataset.modal);
    setIsModal(num);
  };

  // モーダル閉じる
  const onCloseModal = () => {
    setIsModal(-1);
  };

  // モーダルコンテンツ
  const ModalContent = () => {
    const theme = UNKOMUSEUM_CATEGORIES[isModal] && UNKOMUSEUM_CATEGORIES[isModal].theme;
    const name = UNKOMUSEUM_CATEGORIES[isModal] && UNKOMUSEUM_CATEGORIES[isModal].name;
    const detail = UNKOMUSEUM_CATEGORIES[isModal] && UNKOMUSEUM_CATEGORIES[isModal].detail;
    const img = UNKOMUSEUM_CATEGORIES[isModal] && UNKOMUSEUM_CATEGORIES[isModal].img;
    const colorClass = UNKOMUSEUM_CATEGORIES[isModal] && UNKOMUSEUM_CATEGORIES[isModal].colorClass;
    const imgSlider = UNKOMUSEUM_CATEGORIES[isModal] && UNKOMUSEUM_CATEGORIES[isModal].imgSlider;
    const sliderImg = UNKOMUSEUM_CATEGORIES[isModal] && UNKOMUSEUM_CATEGORIES[isModal].sliderImg;
    return (
      <div className={`modal e-modal ${isModal > -1 ? 'is-active' : ''}`}>
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className="modal-background" onClick={onCloseModal} />
        <button
          type="button"
          className="delete"
          aria-label="close"
          data-sal="zoom-in"
          data-sal-delay="300"
          data-sal-easing="ease-out-expo"
          onClick={onCloseModal}
        />
        <div className={`modal-content c-${colorClass}`}>
          <div className="e-modal-image">
            <figure className="image">
            {imgSlider ? (
              <SimpleSwiper data={sliderImg} />
            ) : (
              <Image filename={img || 'now-printing.jpg'} />
            )}
            </figure>
          </div>
          <div className="e-modal-content">
            <p className="theme">
              {theme}
            </p>
            <h3 className="ttl c-4">{name}</h3>
            <p
              dangerouslySetInnerHTML={{ __html: detail }}
            />
          </div>
        </div>
      </div>
    );
  };
  const [fetchReady, setFetchReady] = useState(false);

  useEffect(() => {
    setFetchReady(true);
  }, []);

  return (
    <Layout pageSlug={pageSlug}>
      <SEO title={pageTitle} description={pageDescription} />
      <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
      <section className="pageMain bg-12 bs-4">
        <div className="kv-wrap">
          <div className="kv-inner">
            <SimpleSwiper data={sliderDataobj} />
            {/* <p className="credit">&copy;「チームラボフォレスト 福岡 - SBI証券」 福岡 E・ZO FUKUOKA</p> */}
          </div>
          <div className="container">
            <div className="inner tac">
              <div className="pageMain-header">
                <div className="floorNo c-9">
                  <span>7</span>
                  <span>F</span>
                </div>
                <h2 className="ttl">
                  <Image src={pageLogo} alt={pageTitle} />
                </h2>
              </div>
              {url.ticketReady === '1' ? (
                <div
                  className="btn-wrap mb-0"
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <Button
                    className="btn-buy"
                    href="https://ticket.e-zofukuoka.com/#/order?id=E5KT8EKDXWYL3JT6IJZU6ADEADGYNSAG6SASAN387H3VU0MBQ258O7DOK3D3GLVI"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    チケットを探す
                  </Button>
                </div>
              ) : null}
              <div
                  className="btn-wrap mb-0"
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <a href="#unkoDiscountTicket" className="btn-regular" onClick={(e) => ankerLinkScroll(e, "unkoDiscountTicket")} onKeyDown={(e) => ankerLinkScroll(e, "unkoDiscountTicket")}>
                    <span>【期間限定】<br />お得なセットチケット</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="14" viewBox="0 0 18.438 14.47" className='icon-anker'>
                      <path id="anker-arrow.svg" className="cls-1" d="M402.711,2438.44v-6.12a0.713,0.713,0,0,0-1.152-.57l-7.628,6.15a0.7,0.7,0,0,1-.883,0l-7.606-6.13a0.715,0.715,0,0,0-1.154.57v6.13a0.739,0.739,0,0,0,.271.57l8.489,6.83a0.7,0.7,0,0,0,.883,0l8.51-6.86a0.709,0.709,0,0,0,.27-0.57" transform="translate(-384.281 -2431.59)"/>
                    </svg>
                  </a>
                </div>
              {/* <div className="btn-group mt-5">
                <Link
                  to="/news/info/20230311252/"
                  className="btn-def caution-ticket-btn narrow"
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <span>新型コロナウイルス感染防止の対策について</span>
                </Link>
              </div> */}
              {/* <div className="caution-border mt-5 mb-5">
                かかとの高い靴、サンダルなどのお足元が不安定な靴ではご体験いただけない作品が多くあります。
              </div> */}
              {/* <div className="youtube-wrap mt-4 mb-4 cn-hide">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/FCmDC_xicJQ"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <PostListComponent />
      <section className="section sc-topics bs-topics">
        <div className="container">
          <div className="inner mb0">
            <h2 className="headline" id="area">
              <span>エリア</span>
            </h2>
            <div className="sc-columns now-event">
              {UNKOMUSEUM_CATEGORIES.map((categories, index) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <div
                  className={`col col-4 ${categories.colorClass}`}
                  key={categories.name}
                  data-modal={index}
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                  onClick={(e) => onClickModal(e)}
                >
                  <ContentsListUnko {...categories} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="section sc-topics bs-topics" id="unkoGoods">
        <div className="container">
          <div className="inner mb0">
            <h2 className="headline">
              <span>グッズ</span>
            </h2>
            <p className='text is-4 mb10 fwb  has-text-align-center'>会場外のグッズショップで<br className='sp'/>販売中の商品を一部ご紹介！</p>
            <p className='text mb40'>※商品は売り切れとなる場合がございます。なお、在庫、再入荷の有無・入荷予定日につきましてはお答えいたしかねますのでご了承ください</p>
            <div className="sc-columns now-event">
              {UNKOMUSEUM_GOODS.map((categories, index) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <div
                  className={`col col-4 ${categories.colorClass}`}
                  key={categories.name}
                  data-modal={index}
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <ContentsListUnko {...categories} />
                </div>
              ))}
            </div>
          </div>
          {/* <div className="btn-wrap mt-6">
            <Button
              href="/files/unkomuseum/unkomuseum-goods.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              グッズ一覧(PDF)
            </Button>
          </div> */}
        </div>
      </section>
      <section className="section sc-topics bs-topics" id="unkoCollabo">
        <div className="container">
          <div className="inner mb0">
            <h2 className="headline">
              <span>MLB café FUKUOKA<br />コラボメニュー</span>
            </h2>
            <p className='text is-4 mb30 fwb  has-text-align-center'>BOSS E・ZO FUKUOKA3F「MLB café FUKUOKA 」ではコラボメニューのヘルメットソフトクリームを販売中！<br className="sp" />マイうんこを提示で100円引きになります。</p>
            <div className="wrap-sp mb30">
              <div className="col6">
                <div className="col6-inner ">
                  <div className="image">
                    <Image filename="img-unko-soft.jpg" />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="btn-wrap mb-0"
              data-sal="slide-up"
              data-sal-easing="ease-out-expo"
            >
              <Button
                className="btn-regular"
                href="/the-foodhall/mlbcafe-fukuoka/"
              >
                MLB café FUKUOKA<br />の情報はこちらから
              </Button>
            </div>
          </div>
        </div>
      </section>
      <section className="section" id="unkoDiscountTicket">
        <div className="container sp-mb60">
          <div className="inner mb0">
            <h2 className="headline">
              <span>お得なチケット</span>
            </h2>
            <p className='text is-4 mb40 sp-mb20 fwb has-text-align-center'>9月3日(日)までの期間限定でお得なセットチケットを販売しております。</p>
            <div className="wrap-sp mb30">
              <div className="col6">
                <div className="col6-inner ">
                  <div className="image">
                    <Image filename="unkomuseum-otoku-ticket.jpg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="table-base park-ticket new-table">
              <table><tbody>
                <tr>
                  <th>セット内容</th>
                  <td>
                    <p className='mb10'>ただいま!!うんこミュージアムFUKUOAKA presented by CHIKYUJIN</p>
                    <p>Immersive Museum FUKUOKA supported by FORTINET</p>
                  </td>
                </tr>
                <tr>
                  <th>販売価格</th>
                  <td>
                    <p className='mb10'>大人（大学生～）：3,200円（定価：3,500～3,700円）</p>
                    <p className='mb10'>中高生：2,400円（定価：2,600～2,800円）</p>
                    <p>小人（4歳以上）：1,200円（定価：1,300～1,500円）</p>
                  </td>
                </tr>
              </tbody></table>
            </div>
            <div
              className="btn-wrap mb-0"
              data-sal="slide-up"
              data-sal-easing="ease-out-expo"
            >
              <Button
                className="btn-buy"
                href="https://ticket.e-zofukuoka.com/#/order?id=8I7908Y82KSWL78REW2QSJ608AI4UPXXF20U5683TI9JOF4ERANX0NNXPOZJACAW"
                target="_blank"
                rel="noopener noreferrer"
              >
                チケット購入はこちら
              </Button>
            </div>
          </div>
        </div>
        <div className="inner-slim">
          <div className="table-base">
            <table>
              <tbody>
                <tr>
                  <th>チケット料金</th>
                  <td>
                    <p>
                      【平日】
                      <br />
                      大人: 1,600円
                      <br />
                      中高生: 1,200円
                      <br />
                      小人（4歳以上）: 700円
                    </p>
                    <p>
                      【土日祝・特定日】
                      <br />
                      大人: 1,800円
                      <br />
                      中高生: 1,400円
                      <br />
                      小人（4歳以上）: 900円
                    </p>
                    <p className="list-mark">
                      <span>※</span>
                      障がい者料金は各200円引き。
                    </p>
                    {url.ticketReady === '1' ? (
                      <p className="underline-link">
                        <a
                          href="https://ticket.e-zofukuoka.com/#/order?id=E5KT8EKDXWYL3JT6IJZU6ADEADGYNSAG6SASAN387H3VU0MBQ258O7DOK3D3GLVI"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          チケット購入はこちら
                        </a>
                      </p>
                    ) : null}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h4 className="title is-5">注意事項</h4>
          <article>
            <ul className="list-base">
              <li>
                当館ご利用の際のお怪我や損失・損害、その他トラブルなどにつきまして当館では一切責任を負いません。
              </li>
              <li>
                営業中止を除き、いかなる場合もチケットの払い戻しは行っておりません。
              </li>
              <li>
                チケットの転売は禁止しており、転売にて購入いただいたチケットではご入場頂けず、その場合も当館は一切の責任を負いません。
              </li>
              <li>
                お子様連れのお客様は、保護者様の監督責任のもと安全にお過ごしください。
              </li>
              <li>
                <b>
                  9歳以下のお子様のご入場には、保護者の同伴が必要です。
                </b>
              </li>
              {fetchReady
                ? TimerFunc(
                    '2021-04-23 00:00:00',
                    '2021-05-01 00:00:00',
                    <li>
                      <b>
                        ※保護者1名に対し未就学児のお子様3名までご入場いただけます。（同伴者もチケット購入が必要です）
                      </b>
                    </li>
                  )
                : null}
              <li>
                <b>
                  ※保護者1名に対し3歳以下のお子様2名までご入場いただけます。（同伴者もチケット購入が必要です）
                </b>
              </li>
              <li>
                イベントスペースでの喫煙、アメ・ガムを含む飲食はお断りしております。
              </li>
              <li>
                <b>会場内にトイレ・休憩室はございません</b>
              </li>
              <li>体調不良・泥酔状態でのご入場はお断りしております。</li>
              <li>
                全長50cm以上の荷物は1Fもしくは3Fのコインロッカー、荷物置場をご利用ください
              </li>
              <li>補助犬を除き、動物を連れてのご入場はお断りしております。</li>
              <li>事前承諾のない商業目的の撮影や取材はお断りしております。</li>
            </ul>

            <h4 className="ttl-strip">持ち込み禁止物</h4>
            <p>
              危険物、臭気物、生花など
              <br />
              ペットボトル、水筒以外の蓋が無いドリンク類
              <br />
              その他、スタッフが危険であると判断した物品
            </p>
          </article>
        </div>
      </section>

      <ModalContent />
    </Layout>
  );
};

export default Template;
