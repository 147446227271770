/**
 * Type: ページ
 * What: うんこミュージアム
 */
import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Button } from '../components/Btn';
import Image from '../util/Image';
import BreadcrumbComponent from '../components/Breadcrumb';
import SimpleSwiper from '../components/Slider.fade';
import {
  UNKOMUSEUM_CATEGORIES,
  UNKOMUSEUM_CATEGORIES1,
  PRICE_CALENDAR,
} from '../config/Unkomuseum';
import ContentsListUnko from '../components/ContentsListUnko';
import PostListComponent from '../components/PostList/PostList.unkomuseum';
import TimerFunc from '../util/Timer';
import mainImg2 from '../images/unkomuseum/main-unkomuseum2025.jpg';

const pageTitle = '＼パワーアップ!!／うんこミュージアム FUKUOKA Supported by 地球人．jp';
const pageDescription =
  'うんこミュージアムが圧倒的進化を遂げて福岡へ！2025年4月18日（金）から開催！';
const pageSlug = 'unkomuseum';
const pageLogo = 'logo-unkomuseum2025.png';

export const query = graphql`
  {
    site {
      siteMetadata {
        uuidEvent
        ticket
        ticketReady
      }
    }
  }
`;

const sliderDataobj = [
  {
    filename: mainImg2,
    noAnimation: true,
  },
];

// Data Props Template
const Template = ({ data }) => {
  const url = data.site.siteMetadata;

  const [isModal, setIsModal] = useState(-1);
  const [isModal1, setIsModal1] = useState(-1);
  const [isPopup, setIsPopup] = useState(-1);

  // モーダル展開
  const onClickModal = (e) => {
    const num = Number(e.currentTarget.dataset.modal);
    setIsModal(num);
  };
  const onClickModal1 = (e) => {
    const num = Number(e.currentTarget.dataset.modal);
    setIsModal1(num);
  };
  const onClickPopup = (e) => {
    const num = Number(e.currentTarget.dataset.modal);
    setIsPopup(num);
  };

  // モーダル閉じる
  const onCloseModal = () => {
    setIsModal(-1);
  };
  const onCloseModal1 = () => {
    setIsModal1(-1);
  };
  const onClosePopup = () => {
    setIsPopup(-1);
  };

  // モーダルコンテンツ
  const ModalContent1 = () => {
    const theme =
      UNKOMUSEUM_CATEGORIES1[isModal1] &&
      UNKOMUSEUM_CATEGORIES1[isModal1].theme;
    const nameimg =
      UNKOMUSEUM_CATEGORIES1[isModal1] &&
      UNKOMUSEUM_CATEGORIES1[isModal1].nameimg;
    const detail =
      UNKOMUSEUM_CATEGORIES1[isModal1] &&
      UNKOMUSEUM_CATEGORIES1[isModal1].detail;
    const img =
      UNKOMUSEUM_CATEGORIES1[isModal1] && UNKOMUSEUM_CATEGORIES1[isModal1].img;
    const colorClass =
      UNKOMUSEUM_CATEGORIES1[isModal1] &&
      UNKOMUSEUM_CATEGORIES1[isModal1].colorClass;
    const imgSlider =
      UNKOMUSEUM_CATEGORIES1[isModal1] &&
      UNKOMUSEUM_CATEGORIES1[isModal1].imgSlider;
    const sliderImg =
      UNKOMUSEUM_CATEGORIES1[isModal1] &&
      UNKOMUSEUM_CATEGORIES1[isModal1].sliderImg;
    return (
      <div className={`modal e-modal ${isModal1 > -1 ? 'is-active' : ''}`}>
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className="modal-background" onClick={onCloseModal1} />
        <button
          type="button"
          className="delete"
          aria-label="close"
          data-sal="zoom-in"
          data-sal-delay="300"
          data-sal-easing="ease-out-expo"
          onClick={onCloseModal1}
        />
        <div className={`modal-content c-${colorClass}`}>
          <div className="e-modal-image">
            <figure className="image">
              {imgSlider ? (
                <SimpleSwiper data={sliderImg} />
              ) : (
                <Image filename={img || 'now-printing.jpg'} />
              )}
            </figure>
          </div>
          <div className="e-modal-content">
            <p className="theme">{theme}</p>
            <h3 className="ttl c-4">
              <Image filename={nameimg} />
            </h3>
            <p dangerouslySetInnerHTML={{ __html: detail }} />
          </div>
        </div>
      </div>
    );
  };
  const ModalContent = () => {
    const theme =
      UNKOMUSEUM_CATEGORIES[isModal] && UNKOMUSEUM_CATEGORIES[isModal].theme;
    const nameimg =
      UNKOMUSEUM_CATEGORIES[isModal] && UNKOMUSEUM_CATEGORIES[isModal].nameimg;
    const detail =
      UNKOMUSEUM_CATEGORIES[isModal] && UNKOMUSEUM_CATEGORIES[isModal].detail;
    const img =
      UNKOMUSEUM_CATEGORIES[isModal] && UNKOMUSEUM_CATEGORIES[isModal].img;
    const colorClass =
      UNKOMUSEUM_CATEGORIES[isModal] &&
      UNKOMUSEUM_CATEGORIES[isModal].colorClass;
    const imgSlider =
      UNKOMUSEUM_CATEGORIES[isModal] &&
      UNKOMUSEUM_CATEGORIES[isModal].imgSlider;
    const sliderImg =
      UNKOMUSEUM_CATEGORIES[isModal] &&
      UNKOMUSEUM_CATEGORIES[isModal].sliderImg;
    return (
      <div className={`modal e-modal ${isModal > -1 ? 'is-active' : ''}`}>
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className="modal-background" onClick={onCloseModal} />
        <button
          type="button"
          className="delete"
          aria-label="close"
          data-sal="zoom-in"
          data-sal-delay="300"
          data-sal-easing="ease-out-expo"
          onClick={onCloseModal}
        />
        <div className={`modal-content c-${colorClass}`}>
          <div className="e-modal-image">
            <figure className="image">
              {imgSlider ? (
                <SimpleSwiper data={sliderImg} />
              ) : (
                <Image filename={img || 'now-printing.jpg'} />
              )}
            </figure>
          </div>
          <div className="e-modal-content">
            <p className="theme">{theme}</p>
            <h3 className="ttl c-4">
              <Image filename={nameimg} />
            </h3>
            <p dangerouslySetInnerHTML={{ __html: detail }} />
          </div>
        </div>
      </div>
    );
  };

  const PopupContent = () => {
    const fileName = PRICE_CALENDAR[isPopup] && PRICE_CALENDAR[isPopup].imgName;

    return (
      <div className={`modal e-modal ${isPopup > -1 ? 'is-active' : ''}`}>
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className="modal-background" onClick={onClosePopup} />
        <button
          type="button"
          className="delete"
          aria-label="close"
          data-sal="zoom-in"
          data-sal-delay="300"
          data-sal-easing="ease-out-expo"
          onClick={onClosePopup}
        />
        <div className="modal-content">
          <div className="e-modal-image">
            <figure className="image">
              <Image filename={fileName || 'now-printing.jpg'} />
            </figure>
          </div>
        </div>
      </div>
    );
  };

  const [fetchReady, setFetchReady] = useState(false);

  useEffect(() => {
    setFetchReady(true);
  }, []);

  return (
    <Layout pageSlug={pageSlug}>
      <SEO title={pageTitle} description={pageDescription} />
      <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
      <style>
        {`
          :root {
                --unko-pink: #ec57b8;
                --unko-pink1: #fbddf1;
                --unko-pink2: #ffe0f7;
                --unko-blue: #00a6e7;
            }
          .headline > span{
            color: var(--unko-pink) !important;
          }
          .sc-topics {
            background-color: transparent;
          }
          .sc-columns .col-content {
            background-color: white;
          }
          .ttl {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 0.625rem;
          }
          .col-content  {
            padding: 7.75% 7.75% 10%;
            line-height: 1.6666666667;
            background-color: white;
            -ms-flex: 1 1;
            flex: 1 1;
            margin-top: auto;
          }
          .no-link {
            cursor: pointer;
            border: 3px solid var(--unko-pink) !important;
          }
          .col.blue .no-link {
            border-color: var(--unko-blue) !important;
          }
          .col-inner {
            height: 100%;
            border: solid 2px white;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-flow: column;
            flex-flow: column;
            overflow: hidden;
          }
          .p-btn-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 10px
          }
          .p-btn-container button {
            background-color: var(--unko-pink);
            color: white;
            font-size: 16px;
            font-weight: bold;
            padding: 5px 20px;
          }
        `}
      </style>

      <section className="pageMain bg-12 bs-4">
        <div className="kv-wrap">
          <div className="kv-inner">
            <SimpleSwiper data={sliderDataobj} />
            {/* <p className="credit">&copy;「チームラボフォレスト 福岡 - SBI証券」 福岡 E・ZO FUKUOKA</p> */}
          </div>
          <div className="container">
            <div className="inner tac">
              <div className="pageMain-header">
                <div className="floorNo" style={{ color: 'var(--unko-pink)' }}>
                  <span>6</span>
                  <span>F</span>
                </div>
                <h2 className="ttl">
                  <Image src={pageLogo} alt={pageTitle} />
                </h2>
              </div>
              <p className="u-fz-20 mb-6">
                <b>期間：4月18日(金)～9月23日(火・祝)</b>
              </p>
              {url.ticketReady === '1' ? (
                <div
                  className="btn-wrap mb-0"
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <Button
                    className="btn-buy"
                    href="https://ticket.e-zofukuoka.com/#/order?id=1HGNJR79I8FV7VJ0SDBWD7TU7CHY2WVBEESAAIIY8A2F5NTO7Q3T83JMHENGHYJ4"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    チケットを探す
                  </Button>
                </div>
              ) : null}
              {/* <div className="btn-group mt-5">
                <Link
                  to="/news/info/20230311252/"
                  className="btn-def caution-ticket-btn narrow"
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <span>新型コロナウイルス感染防止の対策について</span>
                </Link>
              </div> */}
              {/* <div className="caution-border mt-5 mb-5">
                かかとの高い靴、サンダルなどのお足元が不安定な靴ではご体験いただけない作品が多くあります。
              </div> */}
              <div className="youtube-wrap mt-4 mb-4 cn-hide">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/lqMwscamKbw"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div style={{ backgroundColor: 'var(--unko-pink2)' }}>
        <PostListComponent />

        <figure className="u-ta-c u-pX-40">
          <Image filename="unko-belt.png" />
        </figure>

        <section className="section sc-topics">
          <div className="container u-mb-0">
            <div className="inner mb0">
              <h2 className="headline" id="area">
                <Image filename="unko_title_contents.png" />
              </h2>

              <p className="u-ta-c u-fz-16">
                <b>
                  日本初の魅力的なコンテンツから定番の人気体験まで楽しさ満載！
                </b>
              </p>

              <div
                className="u-pc:col2-20 u-sp:vert-20 p-list u-mt-30"
                style={{ justifyContent: 'center' }}
              >
                {UNKOMUSEUM_CATEGORIES1.map((categories, index) => (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                  <div
                    className={`col col-4 ${categories.colorClass}`}
                    key={categories.name}
                    data-modal={index}
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                    onClick={(e) => onClickModal1(e)}
                  >
                    <ContentsListUnko {...categories} />
                  </div>
                ))}
              </div>

              <div className="sc-columns now-event u-mt-30 p-list u-mb-0">
                {UNKOMUSEUM_CATEGORIES.map((categories, index) => (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                  <div
                    className={`col col-4 ${categories.colorClass}`}
                    key={categories.name}
                    data-modal={index}
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                    onClick={(e) => onClickModal(e)}
                  >
                    <ContentsListUnko {...categories} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        <figure className="u-ta-c u-pX-40">
          <Image filename="unko-belt.png" />
        </figure>

        <section className="u-pY-50">
          <div className="container u-mb-0">
            <div className="inner">
              <h2 className="headline">
                <Image filename="unko_title_ticket.png" />
              </h2>

              <figure className="u-ta-c">
                <Image filename="unko-price-table.png" />
              </figure>

              <div className="u-mt-30">
                <h3
                  className="u-ta-c u-fz-18"
                  style={{ color: 'var(--unko-pink)' }}
                >
                  <Image filename="unko_subtitle_calendar.png" />
                </h3>
                <div className="p-btn-container u-mt-10">
                  {PRICE_CALENDAR.map((source, index) => (
                    <button
                      aria-label="詳細を見る"
                      type="button"
                      data-modal={index}
                      onClick={(e) => onClickPopup(e)}
                    >
                      {source.btnText}
                    </button>
                  ))}
                </div>
              </div>

              <p className="u-fz-12 u-ta-c u-mt-10">
                ※6月以降のチケットは発売前です
              </p>

              <div
                className="btn-wrap mb-0 u-mt-30"
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
              >
                <Button
                  className="btn-buy"
                  href="https://ticket.e-zofukuoka.com/#/order?id=1HGNJR79I8FV7VJ0SDBWD7TU7CHY2WVBEESAAIIY8A2F5NTO7Q3T83JMHENGHYJ4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  チケット購入はこちら
                </Button>
              </div>

              <div
                className="u-mt-30 u-pc:p-20 u-sp:p-10"
                style={{ backgroundColor: 'white' }}
              >
                <h4 className="title is-5">注意事項</h4>
                <article>
                  <ul className="list-base">
                    <li>
                      当館ご利用の際のお怪我や損失・損害、その他トラブルなどにつきまして当館では一切責任を負いません。
                    </li>
                    <li>
                      営業中止を除き、いかなる場合もチケットの払い戻しは行っておりません。
                    </li>
                    <li>
                      チケットの転売は禁止しており、転売にて購入いただいたチケットではご入場頂けず、その場合も当館は一切の責任を負いません。
                    </li>
                    <li>
                      お子様連れのお客様は、保護者様の監督責任のもと安全にお過ごしください。
                    </li>
                    <li>
                      <b>
                        12歳以下のお子様のご入場には、保護者の同伴が必要です。
                      </b>
                    </li>
                    <li>
                      <b>
                        保護者1名に対し2歳以下のお子様2名までご入場いただけます。（同伴者もチケット購入が必要です）
                      </b>
                    </li>
                    <li>
                      イベントスペースでの喫煙、アメ・ガムを含む飲食はお断りしております。
                    </li>
                    <li>
                      <b>会場内にトイレ・休憩室はございません</b>
                    </li>
                    <li>体調不良・泥酔状態でのご入場はお断りしております。</li>
                    <li>
                      全長50cm以上の荷物は1Fもしくは3Fのコインロッカー、荷物置場をご利用ください
                    </li>
                    <li>
                      補助犬を除き、動物を連れてのご入場はお断りしております。
                    </li>
                    <li>
                      事前承諾のない商業目的の撮影や取材はお断りしております。
                    </li>
                  </ul>

                  <h4 className="ttl-strip">持ち込み禁止物</h4>
                  <p>
                    危険物、臭気物、生花など
                    <br />
                    ペットボトル、水筒以外の蓋が無いドリンク類
                    <br />
                    その他、スタッフが危険であると判断した物品
                  </p>
                </article>
              </div>
            </div>
          </div>
        </section>
      </div>
      <ModalContent />
      <ModalContent1 />
      <PopupContent />
    </Layout>
  );
};

export default Template;
