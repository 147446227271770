const SLIDER_AREA01 = [
  {
    filename: `img-unko-area-1-1.jpg`,
  },
  {
    filename: `img-unko-area-1-2.jpg`,
  },
  {
    filename: `img-unko-area-1-3.jpg`,
  },
  {
    filename: `img-unko-area-1-4.jpg`,
  },
];
const SLIDER_AREA02 = [
  {
    filename: `img-unko-area-2-1.jpg`,
  },
];
const SLIDER_AREA03 = [
  {
    filename: `img-unko-area-3.jpg`,
  },
];
const SLIDER_AREA04 = [
  {
    filename: `img-unko-area-4-1.jpg`,
  },
];
const SLIDER_AREA05 = [
  {
    filename: `img-unko-area-5.jpg`,
  },
];
const SLIDER_AREA06 = [
  {
    filename: `img-unko-area-6-2.jpg`,
  },
];
const SLIDER_AREA07 = [
  {
    filename: `img-unko-area-7-1.jpg`,
  },
];

export const UNKOMUSEUM_CATEGORIES1 = [
  {
    categories: '', // カテゴリー名
    nameimg: 'unko_subtitle_nagero.png', // エリア名
    description:
      '壁に向かってうんこをなげる、爽快感満点のコンテンツです。うんこで壁を破壊する「うんこ壁こわし」、うんこで花を咲かせる「花さかうんこ」などのステージをお楽しみいただけます。', // 説明
    img: 'うんこをなげろ', // 画像名
    colorClass: 'blue', // シャドウカラー設定
    imgSlider: true,
    sliderImg: [{ filename: `unko-nagero.jpg` }],
    isNew: true,
  },
  {
    categories: '', // カテゴリー名
    nameimg: 'unko_subtitle_dancing.png', // エリア名
    description:
      'うんこダンサーズと一緒に、思う存分踊ることができます。ディスプレイに投影されたキャラクターは動きを感知し、あなたと同じダンスを踊ってくれます。恥ずかしさを水に流すのがポイント！', // 説明
    img: 'ダンシングうんこルーム', // 画像名
    colorClass: 'blue', // シャドウカラー設定
    imgSlider: true,
    sliderImg: [{ filename: `unko-dancing.jpg` }],
    isNew: true,
  },
  {
    categories: '', // カテゴリー名
    nameimg: 'unko_subtitle_egake.png', // エリア名
    description:
      '便器も、見方を変えれば、真っ白なキャンバスに。これまではアナログのボードに描くのみでしたが、大きな便器にデジタルの絵を描けるようになります。可愛いスタンプも押し放題！', // 説明
    img: 'えがけ！みんなのうんこ', // 画像名
    colorClass: 'blue', // シャドウカラー設定
    imgSlider: true,
    sliderImg: [{ filename: `unko-egake.jpg` }],
    isNew: true,
  },
];

export const UNKOMUSEUM_CATEGORIES = [
  {
    categories: 'team-lab', // カテゴリー名
    nameimg: 'unko_subtitle_unsta.png', // エリア名
    detail:
      '<p>ふつうのSNS映えでは満足できない人たちの心を満たす、フォトジェニックな空間。飛び交うシュールなうんこやカラフルに光り輝くうんこなど、さまざまなうんこを撮影してみましょう。</p>', // 説明
    img: 'img-unko-category01.jpg', // 画像名
    imgSlider: true,
    sliderImg: SLIDER_AREA01,
  },
  {
    categories: 'team-lab', // カテゴリー名
    nameimg: 'unko_subtitle_untaractive.png', // エリア名
    detail:
      '<p>地面に映し出されたうんこを踏んづける体感ゲームや、うんこ〜！と叫んで声量に応じたうんこを生み出すゲームなど、子供心をくすぐるゲームをご用意しました。大人の方も、童心に返って全力でお楽しみください。</p>', // 説明
    img: 'img-unko-category02.jpg', // 画像名
    imgSlider: true,
    sliderImg: SLIDER_AREA02,
  },
  {
    categories: 'team-lab', // カテゴリー名
    nameimg: 'unko_subtitle_kusogame.png', // エリア名
    detail:
      '<p>うんこを撮影する、うんこと恋する、うんこにダイブする…。何度でも遊びたくなる、中毒性の高いクソゲーが楽しめます。高得点を出せた時の爽快感は快便以上！</p>', // 説明
    img: 'img-unko-category03.jpg', // 画像名
    imgSlider: true,
    sliderImg: SLIDER_AREA03,
  },
  {
    categories: 'team-lab', // カテゴリー名
    nameimg: 'unko_subtitle_shop.png', // エリア名
    detail:
      '<p>うんこミュージアム前グッズショップでは、うんこグッズの販売もしていますので、水に流せない思い出を持って帰りましょう。</p><p><span class="attention">※</span>グッズショップはミュージアム入場券をお持ちでない方もご入場いただけるエリアです</p>', // 説明
    img: 'img-unko-category04.jpg', // 画像名
    imgSlider: true,
    sliderImg: SLIDER_AREA04,
  },
  {
    categories: 'team-lab', // カテゴリー名
    nameimg: 'unko_subtitle_kachiun.png', // エリア名
    detail:
      '<p>「勝うん様」に参拝し、”うん”をつけよう！黄金に輝く便器に座って写真撮影をお楽しみいただけます。野球観戦の前に立ち寄って、ホークスの勝利を祈願しましょう！</p>', // 説明
    img: 'img-unko-category05.jpg', // 画像名
    imgSlider: true,
    sliderImg: SLIDER_AREA05,
  },
  {
    categories: 'team-lab', // カテゴリー名
    nameimg: 'unko_subtitle_nadeun.png', // エリア名
    detail:
      '<p>祈念を込めて黄金のうんこを撫でて、勝うん(運)をつけよう！あなただけの「マイうんこ」に、ホークス勝利への熱い思いや祈りを込めたメッセージを記入してお供えすると、「勝うん様」に思いが届くかも…？</p>', // 説明
    img: 'img-unko-category06.jpg', // 画像名
    imgSlider: true,
    sliderImg: SLIDER_AREA06,
  },
  {
    categories: 'team-lab', // カテゴリー名
    nameimg: 'unko_subtitle_unkoanimal.png', // エリア名
    detail:
      '<p>2022年、人類のうんこレベル向上によって、今まで肉眼では見えなかった「うんこ動物」が、ようやく見えるようになりました。<br />コロンとした３段の身体に、ふさふさの毛並み、モフモフのシッポ。育った環境によって色や大きさは様々ですが、みんな穏やかな性格で、人間がとても大好き。<br />うんこ動物たちは、今日もシッポをフリフリしながらあなたを待っています。<br />早く会いに来てください。</p>', // 説明
    img: 'img-unko-category07.jpg', // 画像名
    imgSlider: true,
    sliderImg: SLIDER_AREA07,
  },
];

export const PRICE_CALENDAR = [
  {
    btnText: '4月',
    imgName: 'unko-price_calendar-april.png',
  },
  {
    btnText: '5月',
    imgName: 'unko-price_calendar-may.png',
  },
  // {
  //   btnText: '6月',
  //   imgName: 'unko-price_calendar-june.png',
  // },
  // {
  //   btnText: '7月',
  //   imgName: 'unko-price_calendar-july.png',
  // },
];

export const UNKOMUSEUM_GOODS = [
  {
    categories: 'team-lab', // カテゴリー名
    name: 'うんこグミ', // グッズ名
    description: '600円',
    img: 'img-unko-goods-1.jpg', // 画像名
    // colorClass: 'floorbc-tr1', // シャドウカラー設定
    noPointer: true, // リンクホバーなし
    tac: true, // テキスト中央揃え
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: 'キバってましたクッキー', // グッズ名
    description: '1,300円',
    img: 'img-unko-goods-2.jpg', // 画像名
    noPointer: true, // リンクホバーなし
    tac: true, // テキスト中央揃え
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: 'うんこ動物マシュマロ', // グッズ名
    description: '750円',
    img: 'img-unko-goods-3.jpg', // 画像名
    noPointer: true, // リンクホバーなし
    tac: true, // テキスト中央揃え
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: 'うんこペーパー', // グッズ名
    description: '450円',
    img: 'img-unko-goods-4.jpg', // 画像名
    noPointer: true, // リンクホバーなし
    tac: true, // テキスト中央揃え
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: 'うんこ動物キーホルダー', // グッズ名
    description: '500円',
    img: 'img-unko-goods-5.jpg', // 画像名
    noPointer: true, // リンクホバーなし
    tac: true, // テキスト中央揃え
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: 'うんこカチューシャ', // グッズ名
    description: '2,000円',
    img: 'img-unko-goods-6.jpg', // 画像名
    noPointer: true, // リンクホバーなし
    tac: true, // テキスト中央揃え
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: 'ネオンキーホルダー', // グッズ名
    description: '800円',
    img: 'img-unko-goods-7.jpg', // 画像名
    noPointer: true, // リンクホバーなし
    tac: true, // テキスト中央揃え
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: 'うんこTシャツ モノクロ', // グッズ名
    description: '2,800円',
    img: 'img-unko-goods-8.jpg', // 画像名
    noPointer: true, // リンクホバーなし
    tac: true, // テキスト中央揃え
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: 'プニプニうんこ', // グッズ名
    description: '400円',
    img: 'img-unko-goods-9.jpg', // 画像名
    noPointer: true, // リンクホバーなし
    tac: true, // テキスト中央揃え
  },
];
// export const SLIDER_AREA01 = [
//   {
//     filename: `img-unko-area01-1.jpg`,
//   },
//   {
//     filename: `img-unko-area01-2.jpg`,
//   },
// ];
// export const SLIDER_AREA02 = [
//   {
//     filename: `img-unko-area02-1.jpg`,
//   },
//   {
//     filename: `img-unko-area02-2.jpg`,
//   },
// ];
// export const SLIDER_AREA03 = [
//   {
//     filename: `img-unko-area03-1.jpg`,
//   },
//   {
//     filename: `img-unko-area03-2.jpg`,
//   },
// ];
// export const SLIDER_AREA04 = [
//   {
//     filename: `img-unko-area04-1.jpg`,
//   },
//   {
//     filename: `img-unko-area04-2.jpg`,
//   },
// ];
// export const SLIDER_AREA05 = [
//   {
//     filename: `img-unko-area05-1.jpg`,
//   },
//   {
//     filename: `img-unko-area05-2.jpg`,
//   },
// ];
// export const SLIDER_AREA06 = [
//   {
//     filename: `img-unko-area06-1.jpg`,
//   },
//   {
//     filename: `img-unko-area06-2.jpg`,
//   },
// ];
// export const SLIDER_AREA07 = [
//   {
//     filename: `img-unko-area07-1.jpg`,
//   },
//   {
//     filename: `img-unko-area07-2.jpg`,
//   },
// ];
