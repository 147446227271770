/**
 * Type: 投稿コンポーネント
 * What: うんこミュージアム10件の投稿リスト(TOP固定を除く)
 */
import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Card from './Card';
import { Btn, Button, Text, Title } from '../Btn';
import Image from '../../util/Image';

const PostListComponent = () => {
  const QueryData = graphql`
    {
      allWordpressPost(
        filter: {
          categories: { elemMatch: { slug: { eq: "unkomuseum" } } }
          date: { gt: "2025-01-01" }
        }
        limit: 4
      ) {
        nodes {
          title
          path
          excerpt
          date(formatString: "YYYY.MM.DD")
          sticky
          id
          featured_media {
            id
            source_url
          }
          categories {
            id
            slug
            name
          }
        }
      }
    }
  `;
  const postData = useStaticQuery(QueryData);
  const posts = postData.allWordpressPost.nodes;
  return (
    <>
      {posts.length ? (
        <section className="section sc-topics" id="topics">
          <div className="container u-mb-0">
            <h2 className="headline">
              <Image filename="unko_title_topics.png" />
            </h2>
            <div className="columns is-multiline">
              {posts.map((post) => (
                <div className="column is-3" key={post.id}>
                  <Card {...post} />
                </div>
              ))}
            </div>
            <div className="btn-wrap">
              <Button href="/news/unkomuseum/">一覧をみる</Button>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
};

export default PostListComponent;
